<template>
    <div class="grid lg:grid-cols-12 gap-10">
        <div class="lg:col-span-5">
            <UIHeading tag="h2" role="subtitle" class="mb-4">Auto Insights</UIHeading>
            <UIHeading tag="h3" role="main" class="mb-3">Irvine Auto Repair Expert Blog</UIHeading>

            <div class="text-lg font-semibold text-slate-800 md:mb-10">Professional car maintenance tips and advice from your trusted Irvine, CA auto repair specialists</div>

            <UButton to="/blog" variant="solid" size="xl" class="hidden md:inline-flex">Visit Our Blog</UButton>
        </div>
        <div class="lg:col-span-7">
            <div class="grid md:grid-cols-2 gap-11">
                <div class="text-slate-800 flex flex-col justify-between gap-6">
                    <div>
                        <NuxtImg src="images/gallery/gallery-2.webp" loading="lazy" alt="Post 1" class="object-cover w-full rounded-0.5xl" format="webp"/>
                    </div>
                    <div>
                        <h4 class="text-2xl font-bold mb-3">
                            <ULink to="/blog/1">Essential Oil Change Guide</ULink>
                        </h4>

                        <div>Step-by-step instructions for changing your car's oil and maintaining optimal engine performance</div>
                    </div>
                </div>
                <div class="text-slate-800 flex flex-col justify-between gap-6">
                    <div>
                        <NuxtImg src="images/gallery/gallery-3.webp" loading="lazy" alt="Post 1" class="object-cover w-full rounded-0.5xl" format="webp"/>
                    </div>
                    <div>
                        <h4 class="text-2xl font-bold mb-3">
                            <ULink to="/blog/1">Essential Oil Change Guide</ULink>
                        </h4>

                        <div>Step-by-step instructions for changing your car's oil and maintaining optimal engine performance</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="md:hidden">
            <UButton to="/blog" variant="solid" size="xl" class="w-full justify-center">Visit Our Blog</UButton>
        </div>
    </div>
</template>
<script setup lang="ts">
</script>
